import { StaticImage } from "gatsby-plugin-image";
import React from 'react';
import Layout from "../../components/layout";
import Seo from "../../components/seo"
import BlogFeed from "../../components/blog-feed";
import JoinTheTeamForm from "../../components/join-the-team-form";

const HeresLookingAtYou = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Here&#039;s lookin&#039; at YOU!"
        description="Don&#039;t see a role that fits your skillset? No worries! Let&#039;s talk anyway.  We are always open to a conversation, a coffee date, and to talk about what would make you a good addition to our team."
      />
      <section className="pt-6 mb-3">
        <div className="container">
          <h1>Here’s Lookin’ at You!</h1>
          <StaticImage
            src="../../images/jobs/729_DreamJob-01-1080x675.png"
            alt="Dream Job"
          />
          <p className="pt-6">
            Don’t see a role that fits your skillset? No worries! Let’s talk anyway.&nbsp; We are always open to a conversation, a coffee date, and to talk about what would make you a good addition to our team.
          </p>
          <h2>In 300 characters or less…</h2>
        </div>
      </section>

      <section>
        <div className="container">
          <JoinTheTeamForm />
        </div>
      </section>

      <section className="pt-6">
        <BlogFeed />
      </section>
    </Layout>
  );
};

export default HeresLookingAtYou;
